import { createStore, applyMiddleware } from 'redux';
import reducers from './reducers/reducers';
import middlewares from './middlewares/middlewares'

import { setAuth } from '../services/Api'

import { persistedState, saveState } from './persisted.store.js';

function configureStore() {

    const store = createStore(
        reducers,
        persistedState, // second argument overrides the initial state
        applyMiddleware(
            ...middlewares
        )
    );

    // add a listener that will be invoked on any state change
    store.subscribe(() => {
        saveState(store.getState());
    });

    setAuth(store.getState().user)

    return store;

}

export const store = configureStore()