import axios from 'axios'
import qs from 'qs'
import { CancelToken } from 'axios'

export const setAuth = (auth) => {
  apiCredentials.token = auth.token
  apiCredentials.client = auth.client
  apiCredentials.uid = auth.uid
  apiCredentials.expiry = auth.expiry
}

const apiCredentials = {
  token: null,
  client: null,
  uid: null,
  expiry: null
}

class Api {
  constructor() {
    let service = axios.create({
      headers: {
        'access-token': apiCredentials.token,
        'client': apiCredentials.client,
        'uid': apiCredentials.uid,
        'expiry': apiCredentials.expiry,
        'token-type': 'Bearer'
      }
    });
    service.interceptors.response.use(this.handleSuccess, this.handleError);
    this.service = service;
  }

  handleSuccess = (response) => {
    return response;
  }

  handleError = (error) => {
    if (error.response) {
      switch (error.response.status) {
        case 400:
          return error;
        case 401:
          return error.response;
        case 404:
          break;
        default:
          return error.response
      }
    } else {
      return 'bad'
    }
  }

  get(path, cancel, callback) {
    return this.service.get(path, {cancelToken: new CancelToken(c => (cancel.exec = c))}).then(
      (response) => callback(response.status, response.data)
    );
  }

  post(path, payload, callback) {
    return this.service.request({
      method: 'POST',
      url: path,
      responseType: 'json',
      data: payload
    }).then((response) => callback(response.status, response.data));
  }

  put(path, payload, callback) {
    return this.service.request({
      method: 'PUT',
      url: path,
      responseType: 'json',
      data: payload
    }).then((response) => callback(response.status, response.data));
  }

  delete(path, callback) {
    return this.service.request({
      method: 'DELETE',
      url: path,
      responseType: 'json'
    }).then((response) => callback(response.status, response.headers, response.data))
  }

  // Route Specific Methods

  getShipments(params, cancel, callback) {
    this.get(`${process.env.REACT_APP_BASE_API_PATH}/shipments?${qs.stringify(params, {encode: false, arrayFormat: 'brackets'})}`, cancel, callback)
  }
  findShipmentBySlug(slug, callback) {
    this.get(`${process.env.REACT_APP_BASE_API_PATH}/shipment/slug/${slug}`, callback)
  }
  createShipment(payload, callback) {
    this.post(`${process.env.REACT_APP_BASE_API_PATH}/shipments`, payload, callback)
  }
  updateShipment(id, payload, callback) {
    this.put(`${process.env.REACT_APP_BASE_API_PATH}/shipments/${id}`, payload, callback)
  }
  getSlugByAck(ack, callback) {
    this.post(`${process.env.REACT_APP_BASE_API_PATH}/shipments/ack_to_slug`, ack, callback)
  }
 
}

export default Api

