export const SIGN_IN = 'SIGN_IN';
export const SIGN_OUT = 'SIGN_OUT';


/**
 * Sign in current User
 */
export function signIn(authObj) {
    return { type: SIGN_IN, payload: authObj };
}

export function signOut(authObj) {
  return { type: SIGN_OUT, payload: authObj}
}