import React from 'react'
import { Container, Row, Col } from 'reactstrap'

import Shipments from './components/Shipments/Shipments'
import CsvUploader from './components/Uploaders/Csv'

import Routes from './Routes'

class Main extends React.Component {
  render() {
    return(
      <Container>
        <Routes />
      </Container>
    )
  }
}

export default Main