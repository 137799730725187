import React from 'react'
import { Table } from 'reactstrap'
import Api from '../../services/Api'

export default class Shipments extends React.Component {
  constructor(props) {
    super(props)
    this.state = {shipments: []}
  }
  componentDidMount = () => {
    this.fetchShipments()
  }
  fetchShipments = () => {
    let api = new Api()
    api.getShipments({}, {exec: null}, (status, response) => {
      if (status === 200) {
        this.setState({shipments: response})
      }
    })
  }
  render() {
    let shipments = this.state.shipments.map((shipment) => {
    return(<tr key={shipment.id}><td>{shipment.vendor}</td><td>{shipment.slug}</td><td>{shipment.latest_estimated_ship_date}</td><td>{shipment.updated_at}</td></tr>)
    })
    return(
      <Table>
        <tbody>
          {shipments}
        </tbody>
      </Table>
    )
  }
}
