import React from 'react'
import logo from './logo.jpeg'
import { Row, Col, ListGroup, ListGroupItem } from 'reactstrap'

export default class Splash extends React.Component {
  render() {
    return(
      <Row>
        <Col className="text-center">
          <img src={logo} alt="Cthulhu" style={{'max-width': 300}} />
          <hr />
          <ListGroup>
            <ListGroupItem>Palliser <br/><small>(Web Scraped Every 12 hours)</small></ListGroupItem>
            <ListGroupItem>Klaussner <br/><small>(CSV)</small></ListGroupItem>
            <ListGroupItem>Franklin <br/><small>(Web Scraped Every 12 hours)</small></ListGroupItem>
            <ListGroupItem>Southern Motion <br/><small>(CSV)</small></ListGroupItem>
            <ListGroupItem>Jackson Furniture Industries <br/><small>(Web Scraped Every 12 hours)</small></ListGroupItem>
            <ListGroupItem>Natuzzi Editions <br/><small>(CSV)</small></ListGroupItem>
            <ListGroupItem>Comfort Design <br/><small>(CSV)</small></ListGroupItem>
          </ListGroup>
        </Col>
      </Row>
    )
  }
}