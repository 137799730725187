import React from 'react'
import { Row, Col, Button, Input, FormGroup, Table, Badge, Progress } from 'reactstrap'
import { CSVReader } from 'react-papaparse'
import Api from '../../services/Api'

import somoLogo from '../../somo_logo.png'
import comfortDesignLogo from '../../comfort_design.png'
import klaussnerLogo from '../../kfi_logo.jpg'
import servicenetHint from '../../servicenet_hint.png'

class SavedBadge extends React.Component {
  render() {
    if (this.props.saved) {
      return <Badge color="success">Saved</Badge>
    } else {
      return <Badge color="danger">Not Saved</Badge>
    }
  }
}

class DisplayRows extends React.Component {
  render() {
    let rows = this.props.rows.map((row) => {
      return <tr key={row.slug + row.ack}><td>{row.slug}</td><td>{row.date.toLocaleDateString()}</td><td><SavedBadge saved={row.saved} /></td></tr>
    })
    return(
      <Table>
        {rows}
      </Table>
    )
  }
}

const buttonRef = React.createRef()

const southernMotionNormalizer = (data) => {
  let filtered = data.filter((el) => { return el.data[1] !== undefined && el.data[1].includes('TRUROOM') && el.data[3] !== undefined && el.data[3] !== ''})
  let rows = filtered.map((el) => { return {slug: el.data[1], date: new Date(el.data[3]), saved: false} })
  let deDuped = rows.filter((v,i,a)=>a.findIndex(t=>(t.slug === v.slug))===i)
  return deDuped
}

const klaussnerNormalizer = (data) => {
  let filtered = data.filter((el) => { return el.data[2] !== undefined && el.data[2].includes('TRUROOM') && el.data[6] !== undefined && el.data[6] !== ''})
  let rows = filtered.map((el) => { return {slug: el.data[2], date: new Date(`${el.data[6].substring(4,6)}-${el.data[6].substring(6,8)}-${el.data[6].substring(0,4)}`), saved: false} })
  let deDuped = rows.filter((v,i,a)=>a.findIndex(t=>(t.slug === v.slug))===i)
  return deDuped
}

const natuzziEditionsNormalizer = (data) => {
  let filtered = data.filter((el) => { return el.data[3] !== undefined && el.data[3].includes('Open')})
  let rows = filtered.map((el) => { return {slug: '', ack: el.data[2], date: new Date(`${el.data[5].substring(3,5)}-${el.data[5].substring(0,2)}-${el.data[5].substring(6,10)}`), saved: false} })
  let deDuped = rows.filter((v,i,a)=>a.findIndex(t=>(t.ack === v.ack))===i)
  return deDuped
}

class SouthernMotion extends React.Component {
  render() {
    return(
      <div>
        <img src={somoLogo} alt="Southern Motion" className="img-thumbnail" />
        <p>This CSV can be exported straight from <a href="https://www.southernmotion.com/orders/">here</a></p>
        <p>Click the big blue export button and go!</p>
      </div>
    )
  }
}

class Klaussner extends React.Component {
  render() {
    return(
      <div>
        <img src={klaussnerLogo} alt="Klaussner" className="img-thumbnail" />
        <p>This CSV can be exported from <a href="http://servicenet.klaussner.com/OrdersAndDelivery/OrderStatus.aspx">ServiceNet</a></p>
        <img src={servicenetHint} alt="Use CSV option" className="img-fluid" />
        <p>Make sure to use the Excel Spreadsheet option </p>
        <p>You will then need to open it in Excel, then save as CSV and upload here.</p>
      </div>
    )
  }
}

class NatuzziEditions extends React.Component {
  render() {
    return(
      <div>
        <p>Natuzzi Editions</p>
        <p>The spreadsheet can be downloaded from Nares, then uploaded here.</p>
        <p>This process will take a moment to map the ack numbers from the spreadsheet to PO numbers</p>
        <p>You will then need to open it in Excel, then save as CSV and upload here.</p>
      </div>
    )
  }
}

class ComfortDesign extends React.Component {
  render() {
    return(
      <div>
        <img src={comfortDesignLogo} alt="Comfort Design" className="img-thumbnail" />
        <p>This CSV can be exported from <a href="http://servicenet.comfortdesign.com/OrdersAndDelivery/OrderStatus.aspx">ServiceNet</a></p>
        <img src={servicenetHint} alt="Use CSV option" className="img-fluid" />
        <p>Make sure to use the Excel Spreadsheet option </p>
        <p>You will then need to open it in Excel, then save as CSV and upload here.</p>
      </div>
    )
  }
}

class CsvUploader extends React.Component {
  static defaultProps = {
    vendors: {
      'southernMotion': {
        name: 'Southern Motion',
        rowNormalizer: southernMotionNormalizer,
        infoPane: SouthernMotion
      },
      'klaussner': {
        name: 'Klaussner',
        rowNormalizer: klaussnerNormalizer,
        infoPane: Klaussner
      },
      'natuzziEditions': {
        name: 'Natuzzi Editions',
        rowNormalizer: natuzziEditionsNormalizer,
        infoPane: NatuzziEditions
      },
      'comfortDesign': {
        name: 'Comfort Design',
        rowNormalizer: klaussnerNormalizer,
        infoPane: ComfortDesign
      }
    }
  }
  constructor(props) {
    super(props)
    this.state = {rows: [], vendor: 'southernMotion', ackMap: [], uploadProgress: 0, uploadAvailable: false}
  }
  handleOpenDialog = (e) => {
    // Note that the ref is set async, so it might be null at some point
    if (buttonRef.current) {
      buttonRef.current.open(e)
    }
  }
  handleOnFileLoad = (data) => {
    let rows = this.props.vendors[this.state.vendor].rowNormalizer(data)
    if (this.state.vendor === 'natuzziEditions') {
      this.setState({rows: rows}, () => {this.getSlugIdsNatuzzi()})
    } else {
      this.setState({rows: rows}, () => {this.getSlugIds()})
    }
  }
  handleOnError = (err, file, inputElem, reason) => {
    console.log(err)
  }
  handleOnRemoveFile = (data) => {
    console.log('---------------------------')
    console.log(data)
    console.log('---------------------------')
  }
  handleRemoveFile = (e) => {
    // Note that the ref is set async, so it might be null at some point
    if (buttonRef.current) {
      buttonRef.current.removeFile(e)
    }
  }
  getSlugIdsNatuzzi = () => {
    let rows = this.state.rows
    let ackNums = rows.map((el) => el.ack)
    let api = new Api()
    api.getSlugByAck({ack: ackNums}, (status, response) => {
      if (status === 200) {
        this.setState({ackMap: response}, () => {this.mapAckToRow()})
      }
    })
  }
  mapAckToRow = () => {
    let rows = this.state.rows
    rows.map((row) => {
      let ack = this.state.ackMap.find((el) => {return el.ack.includes(row.ack)})
      console.log(ack)
      if (ack !== undefined) {
        row.slug = ack.slug
      }
    })
    let filtered = rows.filter((row) => { return row.slug !== undefined && row.slug !== null && row.slug !== ''})
    this.setState({rows: filtered, uploadAvailable: true})
  }
  getSlugIds = () => {
    let rows = this.state.rows
    let slugs = rows.map((el) => { return el.slug })
    let api = new Api()
    let params = {
      q: {
        slug_in: slugs
      }
    }
    api.getShipments(params, {exec: null}, (status, response) => {
      if (status === 200) {
        response.forEach((resp) => {
          let el = rows.find((el) => { return el.slug === resp.slug})
          el.id = resp.id
        })
      }
    })
    this.setState({rows: rows, uploadAvailable: true})
  }
  saveRows = () => {
    let api = new Api()
    let rows = this.state.rows
    this.setState({uploadProgress: 0})
    let count = 0
    let total = rows.length
    rows.forEach((row) => {
      let params = {
        shipment: {
          slug: row.slug,
          latest_estimated_ship_date: row.date,
          vendor: this.props.vendors[this.state.vendor].name
        }
      }
      if (row.id === undefined) {
        api.createShipment(params, (status, response) => {
          if (status === 201) {
            row.saved = true
            count += 1
            this.setState({rows: rows, uploadProgress: Math.round((count/total)*100)})
          }
        })
      } else {
        api.updateShipment(row.id, params, (status, response) => {
          if (status === 200) {
            row.saved = true
            count += 1
            this.setState({rows: rows, uploadProgress: Math.round((count/total)*100)})
          }
        })
      }
    })
  }
  changeVendor = (e) => {
    this.setState({vendor: e.target.value, rows: [], uploadAvailable: false})
  }
  render() {
    let opts = []
    for (const [key, value] of Object.entries(this.props.vendors)) {
    opts.push(<option key={key} value={key}>{this.props.vendors[key].name}</option>)
    }
    let InfoPane = this.props.vendors[this.state.vendor].infoPane
    return(
      <Row>
        <Col>
          <Row>
            <Col><FormGroup>
            <Input type="select" onChange={this.changeVendor}>{opts}</Input>
          </FormGroup>
          <CSVReader ref={buttonRef}
            onFileLoad={this.handleOnFileLoad}
            onError={this.handleOnError}
            noClick
            noDrag
            noProgressBar
            onRemoveFile={this.handleOnRemoveFile}>
            {({ file }) => (
              <Row>
                <Col>
                  <FormGroup>
                    <Button onClick={this.handleOpenDialog} className="mr-3">Browse file</Button>
                    {file && file.name}
                  </FormGroup>
                </Col>
              </Row>
            )}
          </CSVReader>
          <FormGroup>
            <Button onClick={this.saveRows} disabled={!this.state.uploadAvailable}>Upload</Button>
          </FormGroup>
          <Progress value={this.state.uploadProgress} />
        </Col>
        <Col>
          <InfoPane></InfoPane>
        </Col>
          </Row>
          <Row>
            <Col>
              <DisplayRows rows={this.state.rows} vendor={this.state.vendor} />
            </Col>
          </Row>
        </Col>
      </Row>
    )
  }
}

export default CsvUploader