import React from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom"
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavbarText,
  Button
} from 'reactstrap';
import { connect } from 'react-redux'

import { signOut } from './store/actions/actions'

import Shipments from './components/Shipments/Shipments'
import CsvUploader from './components/Uploaders/Csv'
import Splash from './Splash'

class Routes extends React.Component {
  constructor(props) {
    super(props)
    this.state = {isOpen: false}
  }
  toggle = () => {
    this.setState({isOpen: !this.state.isOpen})
  }
  render() {
    return(
      <Router>
        <Navbar color="light" light expand="md" className="mb-3">
          <Link to="/" className="navbar-brand">Cthulhu</Link>
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="mr-auto" navbar>
              <NavItem>
                <Link to="/csv_import" className="nav-link">CSV Import</Link>
              </NavItem>
              <NavItem>
                <Link to="/shipments" className="nav-link">Shipments</Link>
              </NavItem>
            </Nav>
            <NavbarText><Button onClick={this.props.signOut}>Sign Out</Button></NavbarText>
          </Collapse>
        </Navbar>
        <Switch>
          <Route path="/shipments"><Shipments /></Route>
          <Route path="/csv_import"><CsvUploader /></Route>
          <Route><Splash /></Route>
        </Switch>
        <footer>
          &copy; TruRooms Inc.
        </footer>
      </Router>
    )
  }
}

const mapStateToProps = state => ({ user: state.user })

export default connect(
  mapStateToProps,
  { signOut }
)(Routes);